import { useState } from "react";
import { useHistory } from "react-router-dom";
import { Container, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAuthenticated } from "../../services/api_requests";

import "./AuthPage.css";

const INITIAL_STATE = {
  login: "",
  password: "",
};

export default function AuthPage({ handleAuth, language }) {
  const history = useHistory();

  const [login, setLogin] = useState(INITIAL_STATE.login);
  const [password, setPassword] = useState(INITIAL_STATE.password);

  const inputChangeHandler = (e) => {
    const { name, value } = e.currentTarget;

    switch (name) {
      case "login":
        setLogin(value);
        break;
      case "password":
        setPassword(value);
        break;

      default:
        break;
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      const auth = await _auth();

      setLogin(INITIAL_STATE.login);
      setPassword(INITIAL_STATE.password);

      if (auth) {
        handleAuth(true);
        if (language === "en") {
          history.push("/en");
        } else {
          history.push("/");
        }
      }
    } catch (err) {
      if (language === "en") {
        toast.error(`Error: ${err.message} Try again later.`, {
          position: "top-center",
        });
      } else {
        toast.error(`Ошибка: ${err.message} Попробуйте позже.`, {
          position: "top-center",
        });
      }
    }
  };

  const _auth = async () => {
    let response = await getAuthenticated(login, password);

    let data = await response.json();

    if (data.error) {
      if (language === "en") {
        toast.error("User does not exist!", {
          position: "top-center",
        });
      } else {
        toast.error("Такого пользователя не существует!", {
          position: "top-center",
        });
      }
      return false;
    }

    localStorage.setItem("token", data.token);
    localStorage.setItem("login", data.login);
    return true;
  };

  return (
    <>
      <Container id={"AuthPage"} fluid>
        <h1 className="auth-heading">
          {language === "en"
            ? "Please, login to the app"
            : "Пожалуйста, войдите в приложение"}
        </h1>
        <Form className="auth-form" autoComplete="off" onSubmit={submitHandler}>
          <Form.FloatingLabel
            className="mb-4"
            controlId="formBasicEmail"
            label={language === "en" ? "Login" : "Логин"}
          >
            <Form.Control
              type="text"
              name="login"
              value={login}
              required
              onChange={inputChangeHandler}
              placeholder={language === "en" ? "Login" : "Логин"}
            />
          </Form.FloatingLabel>

          <Form.FloatingLabel
            className="mb-4"
            controlId="formBasicPassword"
            label={language === "en" ? "Password" : "Пароль"}
          >
            <Form.Control
              type="password"
              name="password"
              value={password}
              required
              onChange={inputChangeHandler}
              placeholder={language === "en" ? "Password" : "Пароль"}
            />
          </Form.FloatingLabel>

          <Button variant="primary" type="submit">
            {language === "en" ? "Login" : "Войти"}
          </Button>
        </Form>
      </Container>
    </>
  );
}
