import React from 'react';
// import {Container, Dropdown} from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';

import './decline_reason_list.css';

function DeclineReasonList (params){
  return (
    <div title={params.rule}>
      <CreatableSelect
        value={{value: params.selectedReason, label: params.selectedReason}}
        options={params.data}
        onChange={(e)=>{params.onChooseReason(params.rowNumber, e)}}
        classNamePrefix="custom-select"
      />
    </div>
  );
}

export default DeclineReasonList;
