import { useState, useEffect } from "react";
import { Switch } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import {
  Container,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";

import { getYandexLink } from "./services/api_requests";

import "react-toastify/dist/ReactToastify.css";

import "./App.css";

import logo from "./assets/pre_logo_rus.png";
import logoEn from "./assets/pre_logo_eng.png";
import Book from "./pages/main_page/components/icons/Book";

import PrivateRoute from "./route_components/PrivateRoute";
import PublicRoute from "./route_components/PublicRoute";
import MainPage from "./pages/main_page/MainPage";
import AuthPage from "./pages/auth_page/AuthPage";
import CustomContentContainer from "./special_components/CustomContentContainer";

function App() {
  const location = useLocation();
  const history = useHistory();

  const [checking, setChecking] = useState(true);
  const [auth, setAuth] = useState(false);
  const [language, setLanguage] = useState(() => {
    const lang = location.pathname.slice(location.pathname.length - 2);
    if (lang === "en") {
      return "en";
    } else {
      return "ru";
    }
  });
  const [urlText, setUrlText] = useState("");
  const [urlId, setUrlId] = useState("");
  const [urlCheckable, setUrlCheckable] = useState("1");

  const token = localStorage.getItem('token');

  useEffect(() => {

    if (token) {
      setAuth(true);
    }

    setChecking(false);
  }, [location.pathname, token]);

  useEffect(() => {
    const string = decodeURI(location.search);

    if (string.trim()) {
      const arr = string.split("/?");
      console.log(arr);

      const refactoredArr = arr.map((el, i) => {
        if (i === 0) {
          return el.slice(1);
        } else {
          return el;
        }
      });

      refactoredArr.forEach((el) => {
        if (el.includes("text")) {
          setUrlText(el.slice(5).split("_").join(" "));
        } else if (el.includes("id")) {
          setUrlId(el.slice(3));
        } else {
          setUrlCheckable(el.slice(-1));
        }
      });
    }
  }, [location.search]);

  const handleLanguage = (e) => {
    e.preventDefault();
    const lang = e.target.value;
    setLanguage(lang);
    if (lang === "en") {
      history.push("/en");
    } else {
      history.push("/");
    }
  };

  const exit = () => {
    localStorage.removeItem("token");
    setAuth(false);
    if (language === "en") {
      history.push("/auth/en");
    } else {
      history.push("/auth");
    }
  };

  const download = async () => {
    try {
      const response = await getYandexLink();
      const data = await response.json();
      let tempLink = document.createElement("a");
      tempLink.href = data.href;
      tempLink.target = "_blank";
      tempLink.rel = "noopener noreferrer";
      tempLink.click();
    } catch (err) {
      const message =
        this.params.language === "en"
          ? `Error: ${err.message} Try to download this file later.`
          : `Ошибка: ${err.message} Попробуйте скачать файл позже.`;
      toast.error(message, {
        position: "top-center",
      });
    }
  };

  return (
    <>
      {!checking && (
        <>
          <CustomContentContainer type="header_content">
            <Container fluid id="header-block">
              <div className="logo__wrapper">
                <img
                  src={language === "en" ? logoEn : logo}
                  alt="logo"
                  width="300"
                  height="47"
                />
              </div>
              <div className="lang-switcher">
                <Form.Select
                  aria-label="Language select"
                  size="sm"
                  className="lang-select"
                  name="language"
                  value={language}
                  onChange={handleLanguage}
                >
                  <option value="ru">RU</option>
                  <option value="en">EN</option>
                </Form.Select>
              </div>
              <OverlayTrigger
                placement="left"
                overlay={
                  <Tooltip id={`tooltip-manual`}>
                    {language === "en"
                      ? "Download User manual"
                      : "Скачать Инструкцию"}
                  </Tooltip>
                }
              >
                <Button
                  className={"manual-btn"}
                  variant="outline-secondary"
                  onClick={download}
                >
                  <Book />
                </Button>
              </OverlayTrigger>
              {auth && (
                <Button
                  className={"logout-btn"}
                  variant="outline-secondary"
                  onClick={exit}
                >
                  {language === "en" ? "Logout" : "Выйти"}
                </Button>
              )}
            </Container>
          </CustomContentContainer>
          <Switch>
            <PrivateRoute path="/" exact auth={auth} redirectTo="/auth">
              <MainPage
                language={language}
                urlText={urlText}
                urlId={urlId}
                urlCheckable={urlCheckable}
              />
            </PrivateRoute>
            <PrivateRoute path="/en" exact auth={auth} redirectTo="/auth/en">
              <MainPage language={language} />
            </PrivateRoute>
            <PublicRoute path="/auth" exact auth={auth} redirectTo="/">
              <AuthPage handleAuth={setAuth} language={language} />
            </PublicRoute>
            <PublicRoute path="/auth/en" exact auth={auth} redirectTo="/en">
              <AuthPage handleAuth={setAuth} language={language} />
            </PublicRoute>
          </Switch>
        </>
      )}
      <ToastContainer autoClose={3000} />
    </>
  );
}

export default App;
