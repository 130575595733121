function Bed() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 24 24">
      <path d="M19.25 12H12.75C12.3523 12.0005 11.971 12.1586 11.6898 12.4398C11.4086 12.721 11.2505 13.1023 11.25 13.5V18H2.8V10.5H1V23H2.8V19.8H21.2V23H23V15.75C22.9988 14.7558 22.603 13.8027 21.9 13.0996C21.197 12.3966 20.2442 12.0012 19.25 12ZM21.2 18H13V13.8H18.95C19.5465 13.8007 20.1182 13.7982 20.54 14.22C20.9618 14.6418 21.1993 15.1535 21.2 15.75V18Z" />
      <path d="M6.57456 13.3004C6.79707 13.3004 7.01457 13.3664 7.19958 13.49C7.38458 13.6137 7.52878 13.7894 7.61393 13.9949C7.69907 14.2005 7.72135 14.4267 7.67794 14.6449C7.63454 14.8631 7.52739 15.0636 7.37006 15.2209C7.21272 15.3783 7.01227 15.4854 6.79404 15.5288C6.57581 15.5722 6.34961 15.55 6.14404 15.4648C5.93848 15.3797 5.76277 15.2355 5.63916 15.0505C5.51554 14.8654 5.44956 14.6479 5.44956 14.4254C5.4499 14.1272 5.56853 13.8412 5.77944 13.6303C5.99034 13.4194 6.2763 13.3008 6.57456 13.3004ZM6.57456 11.8004C6.05539 11.8004 5.54787 11.9544 5.11619 12.2428C4.68451 12.5313 4.34806 12.9412 4.14938 13.4209C3.9507 13.9006 3.89871 14.4283 4 14.9375C4.10129 15.4467 4.35129 15.9145 4.71841 16.2816C5.08552 16.6487 5.55325 16.8987 6.06245 17C6.57165 17.1013 7.09945 17.0493 7.57911 16.8506C8.05876 16.6519 8.46873 16.3155 8.75717 15.8838C9.04561 15.4521 9.19956 14.9446 9.19956 14.4254C9.19956 13.7292 8.923 13.0616 8.43072 12.5693C7.93843 12.077 7.27075 11.8004 6.57456 11.8004Z" />
      <path d="M16 4.25H13V1.25H11.25V4.25H8.25V6H11.25V9H13V6H16V4.25Z" />
    </svg>
  );
}

export default Bed;
