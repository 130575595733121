import {Container, Button, Row, Col} from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import {Type} from 'react-bootstrap-table2-editor';
import {Input} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import { createFilter } from 'react-select';

import './services_table.css';

import DeclineReasonList from '../decline_reason_list/decline_reason_list';

import Trash from '../icons/trash';
import Copy from '../icons/copy';
import CustomOption from '../custom_helpers/CustomOption';


const ServicesTable = (params) => {
    let inputFormatter = function (cell, row, rowIndex, formatExtraData) {
      return (
        <textarea
        key={`comment-${rowIndex}`}
        autoFocus={row.serviceName !== ''}
        className={'textbox'}
        onChange={(e)=>{
          formatExtraData.onChange(this.dataField, rowIndex, e);
        }}
        onFocus = {(e)=>{
          let newValue = e.target.value;
          e.target.value = '';
          e.target.value = newValue;
        }}
        value={cell}>
        </textarea>
      )
    };

    let countFormatter = function (cell, row, rowIndex, formatExtraData) {
      return (
        <input type="number" min="1" key={`count-${rowIndex}`}
        onChange={(e)=>{
          formatExtraData.onChange(this.dataField, rowIndex, e);
        }}
        onFocus={(e)=>{
          let newValue = parseInt(e.target.value);

          if (isNaN(newValue) || newValue < 1){
            newValue = 1;
          }

          e.target.value = '';
          e.target.value = newValue;
        }}
        value={cell}/>
      )
    }

    let toothNumFormatter = function(cell, row, rowIndex, formatExtraData){
      if (formatExtraData.isTooth(rowIndex)){
        return (
          <input autoFocus key={`tooth-${rowIndex}`}
          onChange={(e)=>{
            formatExtraData.onChange(this.dataField, rowIndex, e);
          }}
          onFocus={(e)=>{
            let newValue = e.target.value;

            e.target.value = '';
            e.target.value = newValue;
          }}
          value={cell}/>
        )
      } else {
        return cell;
      }

    }

    let selectFormatter = function(cell, row, rowIndex, formatExtraData){
      return (
        <div title={cell}>
          <CreatableSelect value={{ value: cell, label: cell }}
            autoFocus={Boolean(!cell)}
            openMenuOnFocus
            onChange={(e)=>{formatExtraData.onChange(this.dataField, rowIndex, e)}}
            options={formatExtraData.language === 'en' ? formatExtraData.servicesOptionsEn : formatExtraData.servicesOptions}
            filterOption={createFilter({ ignoreAccents: false })}
            components={{ 
              Option: CustomOption,  
            }}
            classNamePrefix="custom-select"
            isLoading={formatExtraData.isLoading}
            loadingMessage={() => formatExtraData.language === 'en' ? 'Loading...' : 'Загружаю...'}
          />
        </div>
      )
    }

    const columns = [
    {
      dataField: '_id',
      text: '_id',
      isKey: true,
      hidden: true
    },
    {
      dataField: 'copyName',
      text: '',
      formatExtraData: params.language === 'en' ? "Copy service" : "Копировать название",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button 
            onClick={(e)=> {params.copyToClipboard(row.serviceName)}} 
            className={'action-icon action-icon__copy'} 
            title={formatExtraData}
          >
            <Copy/>
          </button>
        )
      },
      headerStyle: () => { return {width: '2%'} },
      style: {
      }
    },
    {
      dataField: 'serviceName',
      text: params.language === 'en' ? "Service name" : 'Наименование услуги',
      formatter: selectFormatter,
      formatExtraData: {
        editable: true,
        onChange: params.onChangeName,
        servicesOptions: params.servicesOptions,
        servicesOptionsEn: params.servicesOptionsEn,
        copyToClipboard: params.copyToClipboard,
        language: params.language,
        isLoading: params.isLoading
      },
      headerStyle: () => { return {width: '35%'} },
      style: {
        'padding': '1%'
      }
    },
    {
      dataField: 'removeRow',
      text: '',
      formatExtraData: params.language === 'en' ? "Delete service" : "Удалить услугу",
      formatter: (cell, row, rowIndex, formatExtraData) => {
        return (
          <button onClick={(e)=> {params.onRemove(rowIndex)}} className={'action-icon action-icon__trash'} title={formatExtraData} >
            <Trash/>
          </button>
        )
      },
      headerStyle: () => { return {width: '5%'} },
      style: {
        'padding': '1%'
      }
    },
    {
      dataField: 'toothNum',
      text: params.language === 'en' ? "Tooth number" : '№ Зуба',
      headerStyle: () => { return {width: '5%'} },
      style: {
        'padding': '1%'
      },
      formatExtraData: {
        onChange: params.onChangeToothNum,
        isTooth: params.isTooth
      },
      formatter: toothNumFormatter
    },
    {
      dataField: 'serviceCount',
      text: params.language === 'en' ? "Quantity" : 'Кол-во',
      headerStyle: () => { return {width: '5%'} },
      style: {
        'padding': '1%'
      },
      formatExtraData: {
        onChange: params.onComment,
      },
      formatter: countFormatter,
    },
    {
      dataField: 'approveResult',
      text: params.language === 'en' ? "Result" : 'Результат',
      editor: {
        type: Type.CHECKBOX,
        value: 'Y:N',
      },
      formatExtraData: {
        requestIsAnalized: params.requestIsAnalized
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
         if(formatExtraData.requestIsAnalized) return (
          <Container className={'form-switch'}>
            <Input type="checkbox" checked={cell} className={'form-check-input'} onChange={(e)=> {params.onApprove(rowIndex)}}/>
          </Container>
        )
      },
      style: {
        'padding': '1%'
      }
    },
    {
      dataField: 'declineReason',
      text: params.language === 'en' ? "Rejection reason" : 'Причина отказа',
      formatExtraData: {
        data: params.declineReasonList,
        dataEn: params.declineReasonListEn,
        requestIsAnalized: params.requestIsAnalized,
        onChooseReason: params.onChooseReason,
        services: params.data,
        language: params.language
      },
      headerStyle: () => { return {width: '15%'} },
      editor: {
        type: Type.SELECT
      },
      formatter: (cell, row, rowIndex, formatExtraData) => {
        if(formatExtraData.requestIsAnalized && !formatExtraData.services[rowIndex].approveResult) return (
            <DeclineReasonList data={formatExtraData.language === 'en' ? formatExtraData.dataEn : formatExtraData.data}
              selectedReason={formatExtraData.services[rowIndex].declineReason}
              rule={formatExtraData.services[rowIndex].criteria_text}
              rowNumber = {rowIndex}
              onChooseReason={formatExtraData.onChooseReason}/>
        )
        return (
          <>
          </>
        )
      },
      style: {
        'padding': '1%'
      }
    },
    {
      dataField: 'comment',
      text: params.language === 'en' ? "Comment" : 'Комментарий',
      formatExtraData: {
        onChange: params.onComment,
      },
      formatter: inputFormatter,
      headerStyle: () => { return {width: '20%'} }
    }
  ];

  
  return (
    <Container id="ServicesTable" fluid>
      <BootstrapTable
        keyField='_id' columns={columns}
        data={params.data}
        bordered={false}
        rowClasses={'serviceCell'}/>
      <Row className="btn-row">
        <Col md="4">
          <Button variant="outline-primary" size="sm" onClick={params.onAdd}>
            {params.language === 'en' ? "Add service" : "Добавить услугу"}
          </Button>
        </Col>
        <Col md="7">
          <Button variant="outline-primary" size="sm" onClick={params.onApproveAll}>
          {params.language === 'en' ? "Approve all" : "Согласовать все"}
          </Button>
          <Button className="result-btn-decline" variant="outline-danger" size="sm" onClick={params.onDeclineAll}>
          {params.language === 'en' ? "Reject all" : "Отклонить все"}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default ServicesTable;
