import React from 'react';

function Copy(){
  return(
    <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 24 24">
      <path d="M21.1667 6.5V21.1667H6.5V6.5H21.1667ZM23 4.66667H4.66667V23H23V4.66667ZM1 20.25V1H20.25V2.83333H2.83333V20.25H1Z" />
    </svg>
  )
}

export default Copy;
