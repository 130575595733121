import TailSpin from "react-loader-spinner";

import "./OverlayLoader.css";

export default function OverLayLoader() {
  return (
    <div className="overlay-loader__wrapper">
      <TailSpin
        type="Oval"
        color="#0d6efd"
        height={80}
        width={80}
        ariaLabel="loading-indicator"
      />
    </div>
  );
}
