import config from '../config/config.json';

export async function getExelFile(data, token) {
    try {
        const  res  = await fetch(`${config.url}/excel`, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
              'Authorization': token,
            }),
            body: JSON.stringify(data)
          })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getAuthenticated(login, password) {
    try {
        const  res  = await fetch(`${config.url}/auth`, {
            method: 'POST',
             headers: new Headers({
                'Content-Type': 'application/json',
             }),
             body: JSON.stringify({login, password})
          });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getRequestClassified(data, token) {
    try {
        const  res  = await fetch(`${config.url}/request_analyze/v2`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': token,
            }),
            body: JSON.stringify(data)
        })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getDataAnalyzed(data, token) {
    try {
        const  res  = await fetch(`${config.url}/research`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': token,
            }),
            body: JSON.stringify(data)
        })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getDiagnosisOptions(language, token, {signal}) {
    try {
        const  res  =  await fetch(`${config.url}/diagnosis_v2/${language}/`, {
            method: 'GET',
             headers: new Headers({
                 'Content-Type': 'application/json',
                 'Authorization': token,
            }),
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}
export async function getNormativeActs(code, token) {
    try {
        const  res  =  await fetch(`${config.url}/diagnosis/normative_act/${code}/`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': token,
            }),
        });
   
        return res.json();
    } catch (error) {
        throw error;
    }
}

export async function getServicesOptions(language, {signal}) {
    try {
        const  res  = await fetch(`${config.url}/services/${language}`, {
            method: 'GET',
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getDeclinesOptions(language, {signal}) {
    try {
        const  res  = await fetch(`${config.url}/defects/${language}`, {
            method: 'GET',
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getApprovedTask(data, token) {
    try {
        const  res  = await fetch(`${config.url}/approve`, {
            method: 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': token,
            }),
            body: JSON.stringify(data)
        })
        return res;
    } catch (error) {
        throw error;
    }
}

export async function getOrganizationChecked(token, {signal}) {
    try {
        const  res  = await fetch(`${config.url}/organization/${token}`, {
            method: 'GET',
            signal
        });
        return res;
    } catch (error) {
        throw error;
    }
}

// YandexDisc

export async function getYandexLink() {
    try {
        const  res  = await fetch(`https://cloud-api.yandex.net/v1/disk/public/resources/download?public_key=https%3A%2F%2Fdisk.yandex.ru%2Fi%2FhEQHCACSsYE2WQ`, {
            method: 'GET',
        });
        return res;
    } catch (error) {
        throw error;
    }
}
