import { components } from 'react-select';

import './CustomOption.css';

const CustomOption = ({ children, ...props }) => {
    const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
    const newProps = { ...props, innerProps: rest };
    return (
      <components.Option {...newProps} className="custom-option">
        {children}
      </components.Option>
    );
  };

export default CustomOption;
