import "./BlockHeader.css";

export default function BlockHeader({ heading, visibleTables }) {
  return (
    <div
      className="BlockHeader"
      style={visibleTables ? { textAlign: "end" } : {}}
    >
      <span>{heading}</span>
    </div>
  );
}
